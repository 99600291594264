<template>
  <v-app id="inspire">
    <div class="px-4">
      <loading
        :is-show="loadingDialog"
        :is-done="loadingFinished"
        :is-html="isHtml"
        :message="loadingMessage"
        v-on:close-dialog="closeDialog"
      />
      <details-form
        :is-show="isShowDetails"
        :details="details"
        v-on:close-dialog="closeDialog"
      />
      <v-container class="container--fluid grid-list-md text-center">
        <h1 data-cy="account-transaction-title">Account Transactions</h1>
      </v-container>
      <v-card elevation="2" outlined shaped tile class="pa-1">
        <v-card-title>
          <v-container class="">
            <v-row>
              <v-col cols="8">
                <p data-cy="account-details" class="font-weight-bold mr-2 mt-4 text-left">
                  Account #: {{ id }} {{ status }}
                </p>
              </v-col>
              <v-col cols="4">
                <v-btn
                  :block="$vuetify.breakpoint.mdAndDown"
                  class="mt-2 mr-2"
                  color="default"
                  data-cy="back"
                  :to="{ name: 'account' }">
                  Back to Savings Account
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-btn
                  :block="$vuetify.breakpoint.mdAndDown"
                  class="mt-2 mr-2"
                  color="success"
                  data-cy="deposit"
                  @click.stop="showAccountTransactionForm('DEPOSIT')"
                  v-if="hasPermission('ROLE_CREDIT_MAKER') && this.status === 'Active'"
                >
                  Deposit
                </v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn
                  :block="$vuetify.breakpoint.mdAndDown"
                  class="mt-2 mr-2"
                  color="success"
                  data-cy="withdrawal"
                  @click.stop="showAccountTransactionForm('WITHDRAWAL')"
                  v-if="hasPermission('ROLE_DEBIT_MAKER') && this.status === 'Active'"
                >
                  Withdrawal
                </v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn
                  :block="$vuetify.breakpoint.mdAndDown"
                  class="mt-2 mr-2"
                  color="success"
                  data-cy="transfer"
                  @click.stop="showAccountTransactionForm('TRANSFER')"
                  v-if="
                      hasPermission('ROLE_TRANSFER_MAKER') && this.status === 'Active'
                    "
                >
                  Transfer
                </v-btn>
                <account-transaction-form
                  data-cy="account-transaction-form"
                  ref="form"
                  :selected-item="selectedAccountTransaction"
                  :is-show="showFormDialog"
                  :form-type="accountTransactionFormType"
                  v-on:close-dialog="closeDialog"
                  v-on:save-account-transaction="saveAccountTransaction"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
      </v-card>

      <v-tabs v-model="tab" fixed-tabs>
        <v-tab :key="'trx_reqs'">Transaction Requests</v-tab>
        <v-tab :key="'trx_history'">Transaction History</v-tab>
        <v-tab :key="'status_history'">Status Update History</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
          :key="'trx_reqs'"
          v-if="
          hasPermission('ROLE_READ_TRANSACTION_REQUESTS') ||
          hasPermission('ROLE_CREDIT_MAKER') ||
          hasPermission('ROLE_CREDIT_APPROVER') ||
          hasPermission('ROLE_DEBIT_MAKER') ||
          hasPermission('ROLE_DEBIT_APPROVER') ||
          hasPermission('ROLE_TRANSFER_MAKER') ||
          hasPermission('ROLE_TRANSFER_APPROVER')
        "
          class="pt-4"
        >
          <v-data-table
            :hide-default-header="true"
            :headers="headersRequest"
            :items="displayTransactionRequests"
            :search="searchRequests"
            :loading="loading"
            :options.sync="optionRequests"
            :server-items-length="totalTransactionRequests"
          >
            <template v-slot:header="{ props: { headers } }">
              <thead>
              <tr :colspan="headers.length">
                <th v-for="header in headers" :key="header.text">
                  <span class="d-flex justify-center">{{ header.text }}</span>
                </th>
              </tr>
              </thead>
            </template>
            <template v-slot:top>
              <v-text-field
                v-model="searchRequests"
                append-icon="mdi-magnify"
                outlined
                label="Search"
                data-cy="search-requests"
                hint="*Search current page only"
                persistent-hint
                class="mx-4 mb-10"
              ></v-text-field>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
              <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                <td>
                  <span class="d-flex justify-center"> {{ item.id }} </span>
                </td>
                <td>
                  <span class="d-flex justify-center"> {{ item.transactionType }} </span>
                </td>
                <td>
                <span class="d-flex justify-center">
                  ₱ {{ formatMoney(item.amount) }}
                </span>
                </td>
                <td>
                  <span class="d-flex justify-center"> {{ item.dateCreated }} </span>
                </td>
                <td>
                <span class="d-flex justify-center">
                  {{
                    item.source ? `${item.source} - ${item.sourceAccountNumber}` : "n/a"
                  }}
                </span>
                </td>
                <td>
                <span class="d-flex justify-center">
                  {{
                    item.destination
                      ? `${item.destination} - ${item.destinationAccountNumber}`
                      : "n/a"
                  }}
                </span>
                </td>
                <td>
                <span class="d-flex justify-center">
                  {{
                    item.status === 1
                      ? "APPROVED"
                      : item.status === 2
                        ? "REJECTED"
                        : "PENDING"
                  }}
                </span>
                </td>
                <td>
                <span class="d-flex justify-center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        dark
                        x-small
                        color="green"
                        v-bind="attrs"
                        v-on="on"
                        class="mr-2"
                        data-cy="view-request-details"
                        @click.stop="viewDetails(item)"
                      >
                        <v-icon dark medium>mdi-magnify-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Details</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        dark
                        x-small
                        color="green"
                        v-bind="attrs"
                        v-on="on"
                        class="mr-2"
                        data-cy="approve-request"
                        @click.stop="setSelectedRequest(item, 'APPROVE')"
                        v-if="enableApprovalButtons(item.status, item.transactionType)"
                      >
                        <v-icon dark medium>mdi-check</v-icon>
                      </v-btn>
                    </template>
                    <span>Approve</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        dark
                        x-small
                        color="green"
                        v-bind="attrs"
                        v-on="on"
                        class="mr-2"
                        data-cy="reject-request"
                        @click.stop="setSelectedRequest(item, 'REJECT')"
                        v-if="enableApprovalButtons(item.status, item.transactionType)"
                      >
                        <v-icon dark medium>mdi-close</v-icon>
                      </v-btn>
                    </template>
                    <span>Reject</span>
                  </v-tooltip>
                </span>
                </td>
              </tr>
              </tbody>
            </template>
            <template v-slot:no-results>
              <v-alert :value="true" color="error" icon="warning">
                Found no results.
              </v-alert>
            </template>
          </v-data-table>
          <v-dialog
            data-cy="approve-dialog"
            v-model="dialogApprove"
            max-width="500"
            :retain-focus="false"
          >
            <v-card>
              <v-card-title class="headline"> Approve transaction request?</v-card-title>
              <v-card-text>
                Approve {{ selectedRequest.transactionType }} request for transaction ID:
                {{ selectedRequest.id }} amounting to ₱
                {{ formatMoney(selectedRequest.amount) }}?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  data-cy="approve-request"
                  @click.stop="
                setAccountTransactionApproval({
                  referenceNumber: selectedRequest.referenceNumber,
                  transactionType: selectedRequest.transactionType,
                  approval: 'approve',
                })
              "
                >
                  Confirm
                </v-btn>
                <v-btn color="gray darken-1" text @click="dialogApprove = false">
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            data-cy="reject-dialog"
            v-model="dialogReject"
            max-width="500"
            :retain-focus="false"
          >
            <v-card>
              <v-card-title class="headline"> Reject transaction request?</v-card-title>
              <v-card-text>
                Reject {{ selectedRequest.transactionType }} request for transaction ID:
                {{ selectedRequest.id }} amounting to ₱
                {{ formatMoney(selectedRequest.amount) }}?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  data-cy="reject-request"
                  @click.stop="
                setAccountTransactionApproval({
                  referenceNumber: selectedRequest.referenceNumber,
                  transactionType: selectedRequest.transactionType,
                  approval: 'reject',
                })
              "
                >
                  Confirm
                </v-btn>
                <v-btn color="gray darken-1" text @click="dialogReject = false">
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-tab-item>
        <v-tab-item
          v-if="hasPermission('ROLE_READ_TRANSACTIONS')"
          :key="'trx_history'"
          class="pt-4"
        >
          <v-data-table
            :hide-default-header="true"
            :headers="headers"
            :items="displayAccountTransactions"
            :search="searchTransactions"
            :loading="loading"
            :options.sync="optionTransactions"
            :server-items-length="totalAccountTransactions"
          >
            <template v-slot:header="{ props: { headers } }">
              <thead>
              <tr :colspan="headers.length">
                <th v-for="header in headers" :key="header.text">
                  <span class="d-flex justify-center">{{ header.text }}</span>
                </th>
              </tr>
              </thead>
            </template>
            <template v-slot:top>
              <v-text-field
                v-model="searchTransactions"
                append-icon="mdi-magnify"
                outlined
                label="Search"
                data-cy="search-transaction"
                hint="*Search current page only"
                persistent-hint
                class="mx-4 mb-10"
              ></v-text-field>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
              <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                <td>
                  <span class="d-flex justify-center"> {{ item.id }} </span>
                </td>
                <td>
                  <span class="d-flex justify-center"> {{ item.transactionType }} </span>
                </td>
                <td>
                <span class="d-flex justify-center">
                  ₱ {{ formatMoney(item.amount) }}
                </span>
                </td>
                <td>
                  <span class="d-flex justify-center"> {{ item.dateCreated }} </span>
                </td>
                <td>
                <span class="d-flex justify-center">
                  {{
                    item.source ? `${item.source} - ${item.sourceAccountNumber}` : "n/a"
                  }}
                </span>
                </td>
                <td>
                <span class="d-flex justify-center">
                  {{
                    item.destination
                      ? `${item.destination} - ${item.destinationAccountNumber}`
                      : "n/a"
                  }}
                </span>
                </td>
                <td>
                <span class="d-flex justify-center">
                  {{
                    item.runningBalance
                      ? Number(item.runningBalance).toLocaleString()
                      : "-"
                  }}
                </span>
                </td>

                <td>
                <span class="d-flex justify-center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        dark
                        x-small
                        color="green"
                        data-cy="view-transaction-details"
                        v-bind="attrs"
                        v-on="on"
                        class="mr-2"
                        @click.stop="viewDetails(item)"
                      >
                        <v-icon dark medium>mdi-magnify-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Details</span>
                  </v-tooltip>
                </span>
                </td>
              </tr>
              </tbody>
            </template>
            <template v-slot:no-results>
              <v-alert :value="true" color="error" icon="warning">
                Found no results.
              </v-alert>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item
          v-if="hasPermission('ROLE_READ_TRANSACTIONS')"
          :key="'status_history'"
        >
          <status-update-history :account-id="id"></status-update-history>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-app>
</template>

<script>
import API from "@/api/API.js";
import Loading from "@/views/components/Loading.vue";
import AccountTransactionForm from "@/views/components/members-transaction/AccountTransactionForm.vue";
import StatusUpdateHistory from "@/views/components/members-transaction/StatusUpdateHistory.vue";
import DetailsForm from "@/views/components/DetailsForm.vue";
import {mapGetters} from "vuex";

export default {
  name: "AccountTransactions",
  components: {
    Loading,
    AccountTransactionForm,
    StatusUpdateHistory,
    DetailsForm,
  },
  data() {
    return {
      tab: null,
      id: null,
      status: null,
      dialogApprove: false,
      dialogReject: false,
      searchTransactions: "",
      searchRequests: "",
      headers: [],
      headersRequest: [],
      displayAccountTransactions: [],
      currentAccountTransactions: [],
      displayTransactionRequests: [],
      currentTransactionRequests: [],
      loading: true,
      loadingMessage: "",
      loadingDialog: false,
      loadingFinished: false,
      accountTransactionFormType: "CREATE",
      selectedAccountTransaction: {},
      selectedRequest: {},
      page: 1,
      totalAccountTransactions: 0,
      totalTransactionRequests: 0,
      numberOfPagesTransactions: 0,
      numberOfPagesRequests: 0,
      optionTransactions: {
        rowsPerPage: 5,
      },
      optionRequests: {
        rowsPerPage: 5,
      },
      details: {
        title: "",
        item: {},
      },
      queryTransactions: {},
      queryRequests: {},
      isShowDetails: false,
      showFormDialog: false,
      isHtml: false,
    };
  },
  watch: {
    optionTransactions: {
      handler() {
        this.queryDataTransactions(this.id);
      },
      deep: true,
    },
    paramTransactions: {
      handler: function handler() {
        this.filterTransactions();
      },
      deep: true,
    },
    optionRequests: {
      handler() {
        this.queryDataRequests(this.id);
      },
      deep: true,
    },
    paramRequests: {
      handler: function handler() {
        this.filterRequests();
      },
      deep: true,
    },
    $route() {
      this.displayAccountTransactions = [];
      this.totalAccountTransactions = 0;
      this.displayRequestTransactions = [];
      this.totalRequestTransactions = 0;
      this.id = this.$route.params.id;
      this.status = this.$route.params.status;
      this.queryDataTransactions(this.id);
      this.queryDataRequests(this.id);
    },
  },
  activated() {
    console.log("activated");
  },
  deactivated() {
    console.log("deactivated");
  },
  computed: {
    //get role attributes
    ...mapGetters(["attributes", "user", "authorities"]),
    paramTransactions(nv) {
      return {
        ...this.optionTransactions,
        queryTransactions: this.searchTransactions,
      };
    },
    paramRequests(nv) {
      return {
        ...this.optionRequests,
        queryRequests: this.searchRequests,
      };
    },
  },
  async mounted() {
    this.setHeaders();
    this.id = this.$route.params.id;
    this.status = this.$route.params.status;
  },
  methods: {
    hasPermission(authority) {
      if (this.authorities.some((x) => x === authority)) {
        return true;
      }
      return false;
    },
    async queryDataTransactions(id, isSearchTransactions = false) {
      this.loading = true;
      if (isSearchTransactions) {
        this.optionTransactions.page = 1;
      }
      const {page, itemsPerPage} = this.optionTransactions;
      let pageNumber = page - 1;
      try {
        const limit = itemsPerPage;
        const accountTransactionListResponse = await API.getAccountTransactions(
          id,
          pageNumber,
          limit,
          this.queryTransactions
        );
        if (!accountTransactionListResponse || accountTransactionListResponse.error) {
          //error getting data
          console.log(`${accountTransactionListResponse.error}`);
        } else {
          this.displayAccountTransactions = accountTransactionListResponse.transactions;
          this.totalAccountTransactions = accountTransactionListResponse.totalItems
          this.numberOfPagesTransactions = this.totalAccountTransactions.pageCount / itemsPerPage;
          // const hasMoreResponse = await API.getAccountTransactions(
          //   id,
          //   this.totalAccountTransactions + 1,
          //   1,
          //   this.queryTransactions
          // );
          // if (!hasMoreResponse || hasMoreResponse.error) {
          //   //error getting data
          //   console.log(`${hasMoreResponse.error}`);
          // } else {
          //   var hasMoreList = hasMoreResponse;
          //   if (hasMoreList.length > 0) {
          //     this.totalAccountTransactions = this.totalAccountTransactions + 1;
          //     this.numberOfPagesTransactions = this.numberOfPagesTransactions + 1;
          //   }
          // }
        }
        this.currentAccountTransactions = this.displayAccountTransactions;
        this.filterTransactions();
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async queryDataRequests(id, isSearchRequests = false) {
      this.loading = true;
      if (isSearchRequests) {
        this.optionRequests.page = 1;
      }
      const {page, itemsPerPage} = this.optionRequests;
      let pageNumber = page - 1;
      try {
        const limit = itemsPerPage;
        const transactionRequestListResponse = await API.getTransactionRequests(
          id,
          pageNumber,
          limit,
          this.queryRequests
        );
        if (!transactionRequestListResponse || transactionRequestListResponse.error) {
          //error getting data
          console.log(`${transactionRequestListResponse.error}`);
        } else {
          console.log("!")
          console.log(transactionRequestListResponse)
          this.displayTransactionRequests = transactionRequestListResponse.requests;
          this.totalTransactionRequests = transactionRequestListResponse.totalItems;
          this.numberOfPagesRequests = this.totalTransactionRequests.pageCount / rowsPerPage
          // const hasMoreResponse = await API.getTransactionRequests(
          //   id,
          //   this.totalTransactionRequests + 1,
          //   1,
          //   this.queryRequests
          // );
          // if (!hasMoreResponse || hasMoreResponse.error) {
          //   //error getting data
          //   console.log(`${hasMoreResponse.error}`);
          // } else {
          //   var hasMoreList = hasMoreResponse;
          //   if (hasMoreList.length > 0) {
          // this.totalTransactionRequests = this.totalTransactionRequests.totalItems;
          // this.numberOfPagesRequests = this.numberOfPagesRequests + 1;
          // }
        }
        this.currentTransactionRequests = this.displayTransactionRequests;
        this.filterRequests();
        this.loading = false;
        console.log("--------------------!--------")
        console.log(this.currentTransactionRequests)
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    filterTransactions() {
      let search = this.searchTransactions.trim().toLowerCase();
      if (search) {
        this.displayAccountTransactions = this.currentAccountTransactions.filter(
          (item) => {
            return Object.values(item).join(",").toLowerCase().includes(search);
          }
        );
      } else {
        this.displayAccountTransactions = this.currentAccountTransactions;
      }
    },
    filterRequests() {
      let search = this.searchRequests.trim().toLowerCase();
      if (search) {
        this.displayTransactionRequests = this.currentTransactionRequests.filter(
          (item) => {
            return Object.values(item).join(",").toLowerCase().includes(search);
          }
        );
      } else {
        this.displayTransactionRequests = this.currentTransactionRequests;
      }
    },
    setHeaders() {
      var headers = [
        {
          text: "Transaction ID",
          value: "id",
        },
        {
          text: "Type",
          value: "transactionType",
        },
        {
          text: "Amount",
          value: "amount",
        },
        {
          text: "Date Created",
          value: "dateCreated",
        },
        {
          text: "Source",
          value: "source",
        },
        {
          text: "Destination",
          value: "destination",
        },
        {
          text: "Running Balance",
          value: "runningBalance",
        },
        {
          text: "Actions",
          value: "controls",
          sortable: false,
        },
      ];
      this.headers = headers;
      var headersRequest = [
        {
          text: "Transaction ID",
          value: "id",
        },
        {
          text: "Type",
          value: "transactionType",
        },
        {
          text: "Amount",
          value: "amount",
        },
        {
          text: "Date Created",
          value: "dateCreated",
        },
        {
          text: "Source",
          value: "source",
        },
        {
          text: "Destination",
          value: "destination",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Actions",
          value: "controls",
          sortable: false,
        },
      ];
      this.headersRequest = headersRequest;
    },
    showAccountTransactionForm(what) {
      this.accountTransactionFormType = what;
      this.selectedAccountTransaction = {accountId: this.id};
      this.showFormDialog = true;
    },
    closeDialog(type) {
      if (type === "details") {
        this.details.title = "";
        this.details.item = {};
        this.isShowDetails = false;
      } else if (type === "DEPOSIT" || type === "WITHDRAWAL" || type === "TRANSFER") {
        this.showFormDialog = false;
      } else if (type === "loading") {
        this.loadingDialog = false;
        this.$router.go(0);
      } else if (type === "loading-error") {
        this.loadingDialog = false;
      }
    },
    async saveAccountTransaction(value) {
      this.loadingDialog = true;
      this.isHtml = true;
      this.loadingFinished = false;
      if (value) {
        if (value.formType === "DEPOSIT") {
          this.loadingMessage = `Creating new deposit request`;
          setTimeout(async () => {
            const createResponse = await API.depositRequest(value);
            this.loadingFinished = true;
            if (!createResponse || createResponse.error) {
              this.isHtml = false;
              this.loadingMessage = createResponse.error.message;
            } else {
              this.loadingMessage = `Successfully created deposit request`;
              this.showFormDialog = false;
              this.$refs.form.resetForm();
            }
          }, 2000);
        } else if (value.formType === "WITHDRAWAL") {
          this.loadingMessage = `Creating new withdrawal request`;
          setTimeout(async () => {
            const updateResponse = await API.withdrawalRequest(value);
            this.loadingFinished = true;
            if (!updateResponse || updateResponse.error) {
              this.isHtml = false;
              this.loadingMessage = updateResponse.error.message;
            } else {
              this.loadingMessage = `Successfully created withdrawal request`;
              this.showFormDialog = false;
              this.$refs.form.resetForm();
            }
          }, 2000);
        } else if (value.formType === "TRANSFER") {
          this.loadingMessage = `Creating new transfer request`;
          setTimeout(async () => {
            const updateResponse = await API.transferRequest(value);
            this.loadingFinished = true;
            if (!updateResponse || updateResponse.error) {
              this.isHtml = false;
              this.loadingMessage = updateResponse.error.message;
            } else {
              this.loadingMessage = `Successfully created transfer request`;
              this.showFormDialog = false;
              this.$refs.form.resetForm();
            }
          }, 2000);
        } else {
          this.loadingMessage = "Invalid command";
          this.loadingFinished = true;
        }
      } else {
        this.loadingMessage = "Error processing data.";
        this.loadingFinished = true;
      }
    },
    async setAccountTransactionApproval(value) {
      this.loadingDialog = true;
      this.isHtml = true;
      this.loadingFinished = false;
      if (value) {
        this.loadingMessage = `Setting ${value.transactionType} transaction approval to ${value.approval}`;
        setTimeout(async () => {
          const createResponse = await API.transactionApproval(value);
          this.loadingFinished = true;
          this.dialogApprove = false;
          this.dialogReject = false;
          if (!createResponse || createResponse.error) {
            this.isHtml = false;
            this.loadingMessage = createResponse.error.message;
          } else {
            this.loadingMessage = `Successfully set ${value.transactionType} transaction approval to ${value.approval}`;
            this.showFormDialog = false;
          }
        }, 2000);
      } else {
        this.loadingMessage = "Error processing data.";
        this.loadingFinished = true;
        this.dialogApprove = false;
        this.dialogReject = false;
      }
    },
    viewDetails(details) {
      this.details.title = details.externalId;
      this.details.item = Object.assign({}, details);
      this.details.item.amount = details.amount
        ? `₱ ${this.formatMoney(details.amount)}`
        : 0;
      this.isShowDetails = true;
    },
    setSelectedRequest(item, dialogType) {
      if (dialogType === "APPROVE") {
        this.dialogApprove = true;
      } else {
        this.dialogReject = true;
      }
      this.selectedRequest = item;
    },
    enableApprovalButtons(status, transactionType) {
      return (
        (status === 0 &&
          transactionType === "CREDIT" &&
          this.hasPermission("ROLE_CREDIT_APPROVER")) ||
        (status === 0 &&
          transactionType === "DEBIT" &&
          this.hasPermission("ROLE_DEBIT_APPROVER")) ||
        (status === 0 &&
          transactionType === "TRANSFER" &&
          this.hasPermission("ROLE_TRANSFER_APPROVER"))
      );
    },
    formatMoney(money) {
      return money ? money.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") : 0;
    },
  },
};
</script>

<style scoped>
.v-data-table {
  max-width: 100%;
  margin-bottom: 50px;
}
</style>
